<template>
  <div class="common-tab">
    <el-tabs v-bind="$attrs" v-model="activeTab">
      <template v-for="(item, index) in asyncTab" :key="index">
        <el-tab-pane :label="item.title" :name="item.title">

          <component :is="item.component"></component>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script setup lang="ts">
import { useAttrs, ref, provide, shallowRef } from 'vue'

import { cloneDeep } from 'lodash-es'
/* TODO 此处校验有问题 */
interface InterTab {
  title: string
  component: any
}
/* TODO 暂时无法知道这个怎么校验 */
const attrs: any = useAttrs()
const { arr, modelValue } = attrs

const activeTab = ref<string>(modelValue) // 激活项
const asyncTab = shallowRef(cloneDeep(arr)) // 异步tab栏数组

// 异步添加tab栏
const addAsyncTab = (val: InterTab) => {
  asyncTab.value = [...asyncTab.value, val]
  activeTab.value = val.title
}
// 子组件改变activeTab
const changeActiveTab = (val: string) => {
  activeTab.value = val
}

// 向子组件注入这两个属性，以便子组件操作
provide('addAsyncTab', addAsyncTab)
provide('asyncTab', asyncTab)
provide('activeTab', activeTab)

provide('changeActiveTab', changeActiveTab) //注入这个方法，手动改变Tab的激活项

defineExpose({ activeTab })
</script>

<script lang="ts">
export default {
  name: 'CommonTab',
}
</script>

<style lang="scss" scoped>
.common-tab {
  :deep(.el-tabs) {
    .el-tabs__header {
      .el-tabs__item {
        padding: 0;
        margin-right: 30px;
        padding-left: 30px;
        font-size: 28px;
      }

      .el-tabs__item.is-active {
        background-color: #006bc6;
        color: #fff;
        padding-right: 30px;
        font-weight: bold;
      }
    }

    .el-tabs__nav-wrap::after {
      background-color: #006bc6;
    }
  }
}
</style>
